<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import General from "@/services/General";
import ModalAddDashboardAnnouncements from  "@/components/modals/monitor/modalAddDashboardAnnouncements";
import ModalEditTenantDashboard from  "@/components/modals/monitor/modalEditTenantDashboard";
import Swal from "sweetalert2";



export default {
  components: { Layout, PageHeader, ModalAddDashboardAnnouncements, ModalEditTenantDashboard },
  page: {
    title: "Dashboard Announcements",
    meta: [
      {
        name: "dashboard announcements",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      showLoader: false,
      error: null,
      filterData: false,
      filterDataAll: false,
      tableData: null,
      tenant: '',
      title: "Dashboard Announcements",
      items: [
        {
          text: "Dashboard Announcements",
          active: true,
          href: "/",
        },
      ],
      filterInput: {},
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "version",
      sortDesc: true,
      img_url: '',
      isTableLoading: false,
      tenantDashboardData: {},
      fields: [
        {
          key: "dashboard_version",
          label: "Dashboard version",
          visible: false,
        },
        {
          key: "tenant",
          label: "Tenant",
          visible: true,
        },
        {
          key: "text",
          label: "Content text",
          visible: true,
        },
        {
          key: "button_text",
          label: "Button text",
          visible: true,
        },
        {
          key: "button_link",
          label: "Button link",
          visible: true,
        },
        {
          key: "img_url",
          label: "Img Url",
          visible: true,
        },
        {
          key: "background_color",
          label: "Background Color",
          visible: true,
        },
        {
          key: "text_color",
          label: "Text Color",
          visible: true,
        },
        "delete",
      ],
      totalRowsAll: 0,
    };
  },

  async mounted() {
    try {
      await this.getAllDashboardMessages();
    } catch (error) {
      console.error("Error during component initialization:", error);
    }
  },

  methods: {
    async getAllDashboardMessages() {
      this.showLoader = true;
      try {
        this.filterDataAll = true;
        this.isTableLoading = true;
        const response = await Monitor.getAllDashboardMessages();
        //console.log(response)

        this.tableData = response.data.data;
        this.totalRowsAll = this.tableData.length;
      } catch (error) {
        this.error = error.response?.data?.error || "";
        this.tableData = [];
        this.totalRowsAll = 0;
      } finally {
        this.showLoader = false;
        this.isTableLoading = false;
      }
    },

    async deleteDashboardMessages(dashboard_version) {
      try {
        // Show confirmation dialog
        const confirmation = await Swal.fire({
          title: 'Delete Dashboard Message',
          text: "This Dashboard Message will be deleted!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
        if (confirmation.isConfirmed) {
          const response = await Monitor.deleteDashboardMessages(dashboard_version);
          if (response.data.delete) {
            this.getAllDashboardMessages();
            Swal.fire('Deleted!', 'Dashboard Message has been deleted.', 'success');
          } else {
            Swal.fire('Error', 'Failed to delete Dashboard Message', 'error');
          }
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to delete Dashboard Message', 'error');
        console.error('Error deleting Dashboard Message:', error);
      }
    },

    onFiltered(filteredItems) {
      this.totalRowsAll = filteredItems.length;
      this.currentPage = 1;
    },

    callModalEditTenantDashboard(item) {
      this.$bvModal.show("edit_tenant_dashboard");
      this.tenantDashboardData = { ...item };
    },

    callModalAddNewDashboard() {
      this.$bvModal.show("add_dashboard_announcements");
    },
  },

  middleware: "authentication",
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!showLoader">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="mb-3">
                  <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalAddNewDashboard">
                    <i class="mdi mdi-plus me-2"></i>
                    Add Dashboard Announcements
                  </a>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>

              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  :busy="isTableLoading"
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No Data Found"
                  @filtered="onFiltered"
              >
                <template v-slot:cell(dashboard_url)="data">
                  <a :href="data.item.dashboard_url" class="nav-link">
                    <span>{{data.item.dashboard_url}}</span>
                  </a>
                </template>
                <template v-slot:cell(img_url)="data">
                  <img :src="data.item.img_url" class="img-thumbnail rounded mx-auto d-block" :style="{ width: '100px', height: 'auto' }" alt="" />
                </template>
                <template v-slot:cell(background_color)="data">
                  <div
                      :style="{ backgroundColor: data.item.background_color }"
                      class="color-square"
                      title="Background Color"
                  ></div>
                  <p>{{data.item.background_color}}</p>
                </template>
                <template v-slot:cell(text_color)="data">
                  <div
                      :style="{ backgroundColor: data.item.text_color }"
                      class="color-square"
                      title="Text Color"
                  ></div>
                  <p>{{data.item.text_color}}</p>
                </template>

                <!--                              <template v-slot:cell(edit)="data">-->
                <!--                                    <ul class="list-inline mb-0">-->
                <!--                                        <li class="list-inline-item">-->
                <!--                                            <a href="javascript:void(0);" class="px-2 text-primary"  title="Edit Tenant Dashboard" @click="callModalEditTenantDashboard(data.item)">-->
                <!--                                                <i class="uil uil-pen font-size-18"></i>-->
                <!--                                            </a>-->
                <!--                                        </li>-->
                <!--                                    </ul>-->
                <!--                                </template>-->
                <template v-slot:cell(delete)="data">
                  <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Tenant Dashboard"
                     @click="deleteDashboardMessages(data.item.dashboard_version)">
                    <i class="uil uil-trash-alt font-size-18"></i>
                  </a>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRowsAll"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card-body d-flex align-items-center justify-content-center" >
        <b-spinner large></b-spinner>
      </div>
    </div>

    <!-- MODALS-->
    <ModalEditTenantDashboard :tenantDashboardData="tenantDashboardData" @onRefresh="getAllDashboardMessages()"></ModalEditTenantDashboard>
    <ModalAddDashboardAnnouncements @onRefresh="getAllDashboardMessages()"></ModalAddDashboardAnnouncements>
    <!--MODALS -->

  </Layout>
</template>
<style scoped>
.color-square {
  width: 60px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  display: inline-block; /* Ensure they display inline */
  border: 1px solid #ccc; /* Optional: add a border for visibility */
}
</style>


