<script>
import Monitor from '@/services/Monitor';
import General from '@/services/General';
import validationMessages from '@/components/validations';
import { required, maxLength } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


export default {
  components:{
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {

      showLoader: false,
      showModal: false,
      tryingToSubmit: false,
      submitted: false,
      dashboard_version : '',
      text : '',
      button_text : '',
      text_color : null,
      button_link : null,
      background_color : null,
      img: '',
      file: '',
      file_additional: [],
      dropzoneOptions_primary: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
        thumbnailWidth: 200,
        acceptedFiles: 'image/png,image/jpeg', // Allowed file types
        thumbnailMethod: 'contain',
        maxFilesize: 2,
        //acceptedFiles:'image/*',
        maxFiles: 2,
      },
      tenants_options:[{ "text": "ALL", "value": "ALL" }],
      tenant: 'ALL' ,
      csrf_token: localStorage.getItem('csrf_token'),

    };
  },
  watch: {
    // Optional: Watcher to ensure the hex code stays valid
    selectedColor(newVal) {
      if (!/^#[0-9A-Fa-f]{6}$/.test(newVal)) {
        this.selectedColor = '#556ee6'; // Reset to default if invalid hex
      }
    }
  },
  methods: {
    refreshData() {
      this.$emit('onRefresh');
    },

    async createDashboardMessage() {

      this.tryingToSubmit = true;
      this.submitted = true;
      this.showLoader = true;

      try {
        this.showLoader = true;

        let formData = new FormData()
        formData.append('dashboard_version', this.dashboard_version)
        formData.append('tenant', this.tenant)
        formData.append('text', this.text)
        formData.append('button_text', this.button_text)
        formData.append('background_color', this.background_color)
        formData.append('text_color', this.text_color)
        formData.append('button_link', this.button_link)
        formData.append('csrf_token', this.csrf_token)

        if(this.file){
          formData.append('file', this.file)
        }

        const response = await Monitor.createDashboardMessages(formData);

        this.successmsg('Dashboard Message Created!');

        this.refreshData();

        this.closeModal();
      } catch (error) {
        console.error('Error occurred:', error);
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
        this.showLoader = false;

      } finally {
        this.tryingToSubmit = false;
      }
    },

    async getTenants() {
      try {
        this.showLoader = true;
        const response = await Monitor.getTenants();
        var all_tenants = response.data.tenants.map(tenant => ({
          text: tenant,
          value: tenant,
        }));
        Array.prototype.push.apply(this.tenants_options , all_tenants);

      } catch (error) {
        console.log('Error fetching tenants:', error);
      } finally {
        this.showLoader = false;
      }
    },


    selectFile(files){
      setTimeout(() => {
        let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

        if (queuedFiles.length > 1) {
          this.failedmsg('You can only have one primary image')

          files.forEach((value) => {
            this.$refs.myVueDropzone_primary.removeFile(value)
          });
        }

        this.file = queuedFiles[0];

      }, 250);
    },



    closeModal() {
      this.showModal = false;
      this.showLoader = false;
    },

    resetProps() {
      this.submitted = false;
      this.dashboard_version = '';
      this.text = '';
      this.button_text = '';
      this.background_color = '';
      this.text_color = '';
      this.button_link = '';

    },

    async modalShown() {
        this.showModal = true;
        this.getTenants()
    },

  },

};
</script>

<template>
  <b-modal @shown="modalShown" id="add_dashboard_announcements" v-model="showModal" @hidden="resetProps" title="Add Dashboard Announcements" title-class="font-18" size="lg">
    <form @submit.prevent="createDashboardMessage" v-if="!showLoader">
      <div class="row">
        <div class="col-4">
          <b-form-group label="Dashboard Version" label-for="formrow-title-input" class="mb-3">
            <b-form-input
                v-model.trim="dashboard_version"
                type="text"
                id="dashboard_version"
                aria-describedby="title-feedback"
            >
            </b-form-input>
          </b-form-group>
        </div>
         <div class="col-4">
            <b-form-group label="Select Team" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                        v-model="tenant"
                        :options="tenants_options"
                ></b-form-select>
              <!--  <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages> -->
            </b-form-group>
         </div>

          <b-form-group label="Content Text" label-for="formrow-text-input" class="mb-3">
            <b-form-input
                v-model.trim="text"
                type="text"
                id="text"
                aria-describedby="title-feedback"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="Button Text" label-for="formrow-button_text-input" class="mb-3">
            <b-form-input
                v-model.trim="button_text"
                type="text"
                id="button_text"
                aria-describedby="title-feedback"
            >
            </b-form-input>
          </b-form-group>
        <b-form-group label="Button Link" label-for="formrow-button_link-input" class="mb-3">
          <b-form-input
              v-model.trim="button_link"
              type="text"
              id="button_link"
              aria-describedby="title-feedback"
          >
          </b-form-input>
        </b-form-group>
        <b-card-text class="text-center">
          <div class="row mb-3">
          <div class="col-12">
            <h5 class="text-center">Image</h5>

            <vue-dropzone
                id="dropzone_primary"
                ref="myVueDropzone_primary"
                :use-custom-slot="true"
                :options="dropzoneOptions_primary"
                @vdropzone-files-added="selectFile"
                no-progress-bar
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                <h4>Drop file here or click to upload.</h4>
              </div>
            </vue-dropzone>
          </div>
        </div>
        </b-card-text>
        <b-card
            class="mb-3"
        >
          <b-row>
            <!-- Background Color Picker (col-6) -->
            <b-col cols="12" sm="6">
              <!-- Background Color Picker Form Group -->
              <b-form-group
                  id="example-background-color"
                  label-for="formrow-background-color-input"
                  label="Select Background Color"
                  class="mb-3"
              >
                <b-form-input
                    id="background-color"
                    type="color"
                    name="background_color"
                    v-model="background_color"
                    class="form-control form-control-color"
                ></b-form-input>
              </b-form-group>

              <!-- Background Hex Code Input -->
              <b-form-group
                  label="Background Hex Code"
                  label-for="background-hex-code"
                  class="mb-3"
              >
                <b-form-input
                    id="background-hex-code"
                    type="text"
                    v-model="background_color"
                    class="form-control"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Button Color Picker (col-6) -->
            <b-col cols="12" sm="6">
              <!-- Button Color Picker Form Group -->
              <b-form-group
                  id="example-text_color"
                  label-for="formrow-text_color-input"
                  label="Select Text Color"
                  class="mb-3"
              >
                <b-form-input
                    id="text_color"
                    type="color"
                    name="text_color"
                    v-model="text_color"
                    class="form-control form-control-color"
                ></b-form-input>
              </b-form-group>

              <!-- Button Hex Code Input -->
              <b-form-group
                  label="Text Hex Code"
                  label-for="button-hex-code"
                  class="mb-3"
              >
                <b-form-input
                    id="button-hex-code"
                    type="text"
                    v-model="text_color"
                    class="form-control"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

      </div>
    </form>
    <div v-else>
      <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
        <div class="row">
          <div class="col-12">
            <div class="row  align-items-center justify-content-center">
              <b-spinner large></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createDashboardMessage" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
